

























import { Component, Vue } from 'vue-property-decorator';
import { PersonalInformations, Notifications } from '@/components';
import { ACCOUNTTAB } from '@/utils';
import { contextEmployeur } from '@/store';

@Component({
  components: { PersonalInformations, Notifications }
})
export default class Account extends Vue {
  private tabs = [
    { item: this.$t('account.tabs.informations') }
    // A VENIR
    // { item: this.$t('account.tabs.notifications') }
  ];
  private selectedTab = ACCOUNTTAB.INFOS;

  private goToVueEnsemble() {
    this.$router.back();
  }

  get ACCOUNTTAB() {
    return ACCOUNTTAB;
  }

  get employeurInfos() {
    return contextEmployeur.getEmployeur;
  }
}
